import { BdbMlAccessCardProperties } from "./AccessCardInterface";

export const AccessCards: BdbMlAccessCardProperties[] = [
  {
    tagLabel: "Tarjeta",
    titleLabel: "Bloqueo por pérdida o robo",
    typeIcon: "ico-lock-card",
  },
  {
    tagLabel: "Tarjeta",
    titleLabel: "Uso en el exterior",
    typeIcon: "ico-travel",
  },
  {
    tagLabel: "Perfil",
    titleLabel: "Actualizar datos",
    typeIcon: "ico-bank-references",
  },
  {
    tagLabel: "Topes",
    titleLabel: "Modificar topes de cuentas",
    typeIcon: "ico-limit",
  },
];

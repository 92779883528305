import React from "react";
import styles from "./Landing.module.scss";
import handLanding from "../../assets/img/phone-landing.svg";
import { AccessCards } from "../../enum/AccessCardProperties";

const Landing = (): JSX.Element => {
  return (
    <div className={styles["landing"]}>
      <bdb-ml-header back="FALSE" close="FALSE"></bdb-ml-header>
      <img
        className={styles["landing__footer-image"]}
        src={require("../../assets/img/header-landing.png")}
        alt="header-landing"
      />
      <div className={styles["landing__services-section"]}>
        <span
          className={`${styles["landing__services-section__text"]} sherpa-typography-heading-4`}
        >
          Algunos servicios sin ir a la oficina
        </span>
        <div className={styles["landing__services-section__component"]}>
          {AccessCards.map((options, index) => {
            return (
              <bdb-ml-access-card
                data-testid={`bdb-ml-access-card-${index}`}
                key={index}
                title-label={options.titleLabel}
                tag-label={options.tagLabel}
                tag-solid="false"
                type-tag="info"
                type-avatar="icon"
                type-icon={options.typeIcon}
                box-width="118px"
                box-height="94px"
                obfus-title="false"
              ></bdb-ml-access-card>
            );
          })}
        </div>
      </div>
      <div className={styles["landing__financial-info"]}>
        <div className={styles["landing__financial-info__section"]}>
          <div
            className={styles["landing__financial-info__section__content-text"]}
          >
            <span
              className={
                styles["landing__financial-info__section__content-text__header"]
              }
            >
              APLICACIÓN MÓVIL
            </span>
            <span
              className={
                styles["landing__financial-info__section__content-text__middle"]
              }
            >
              Actualiza tus Datos financieros desde tu celular{" "}
            </span>
            <span
              className={`${styles["landing__financial-info__section__content-text__final"]} sherpa-typography-heading-8`}
            >
              Olvídate de las filas. En cualquier momento podrás actualizar tus
              datos desde la App Banca Móvil.{" "}
            </span>
          </div>
          <img
            className={
              styles["landing__financial-info__section__content-text__image"]
            }
            src={handLanding}
            alt=""
          />
        </div>
      </div>
      <bdb-ml-footer-landing
        options="[]"
        options-business="[]"
        show-stores="false"
        show-fogafin="false"
      ></bdb-ml-footer-landing>
    </div>
  );
};

export default Landing;

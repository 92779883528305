import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "../src/pages/landing/landing";
import {
  applyPolyfills,
  defineCustomElements,
} from "@npm-bbta/bbog-dig-dt-webcomponents-lib/loader";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Landing />} />
      </Routes>
    </Router>
  );
};
applyPolyfills()
  .then(() => {
    defineCustomElements();
  })
  .catch();

export default App;
